<template>
  <div v-if="loaded">
    <h4>Genrer e-post signatur</h4>
    <div class="card">
    <div class="card-body">
      <form @submit.prevent="generateSignature()">
        <div class="row">
          <div class="col-lg-6">
            <a-form-item label="Selskap" :required="true">
              <a-select
                  ref="select"
                  v-model="data.company"
                  :options="options1"
                  style="width: 300px"
                ></a-select>
            </a-form-item>
          </div>
          <div class="col-lg-6">
            <a-form-item label="Linkedin profil link" :required="true">
              <a-input placeholder="https://no.linkedin.com/in/navn-etternavn" v-model.trim="data.linkedin"/>
            </a-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <a-button style="width: 200px;" type="primary" htmlType="submit" class="mt-3">Generer e-post signatur</a-button>
          </div>
        </div>
      </form>
    </div>
    </div>
    <div class="card" v-if="signature">
      <div class="card-body">
        <div v-html="signature"></div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from '@/services/firebase'
export default {
  data() {
    return {
      signature: '',
      loaded: false,
      data: {},
      options1: [
        {
          value: 'Skycall',
          label: 'Skycall',
        },
        {
          value: 'Skygruppen',
          label: 'Skygruppen',
        },
      ],
    }
  },
  mounted() {
    firebase.firestore.collection('users').doc(this.$store.state.user.id)
      .get()
      .then(doc => {
        this.data = doc.data()
        this.data.linkedin = ''
        if (doc.data().positions) {
          this.data.company = doc.data().positions.at(-1).company
        }
        if (!this.data.company) {
          this.data.company = 'Skycall'
        }
        this.loaded = true
      })
  },
  methods: {
    generateSignature() {
      let color, companyLogo, linkedinLogo, link, company, adress, phoneIcon, emailIcon, linkIcon, mapsIcon
      switch (this.data.company) {
        case 'Skycall':
          color = '#0160b0'
          company = 'SkyCall AS'
          companyLogo = 'https://skygruppen.no/wp-content/uploads/2020/07/mail-call.png'
          linkedinLogo = 'https://skygruppen.no/wp-content/uploads/2020/07/linkedin-in-brands-1.png'
          phoneIcon = 'https://skygruppen.no/wp-content/uploads/2020/07/phone-alt-solid-1.png'
          emailIcon = 'https://skygruppen.no/wp-content/uploads/2020/07/envelope-solid-1.png'
          linkIcon = 'https://skygruppen.no/wp-content/uploads/2020/07/link-solid.png'
          mapsIcon = 'https://skygruppen.no/wp-content/uploads/2020/07/map-marker-alt-solid.png'
          adress = 'Strandveien 13, 1366 Lysaker'
          link = {
            value: 'https://skycallas.no',
            label: 'skycallas.no',
          }
          break
        case 'Skygruppen':
          color = '#A7622D'
          company = 'SkyGruppen AS'
          adress = 'Strandveien 13, 1366 Lysaker'
          companyLogo = 'https://skygruppen.no/wp-content/uploads/2020/07/mail-gruppen.png'
          linkedinLogo = 'https://skygruppen.no/wp-content/uploads/2020/07/linkedin-in-brands-2.png'
          phoneIcon = 'https://skygruppen.no/wp-content/uploads/2020/07/phone-alt-solid-3.png'
          emailIcon = 'https://skygruppen.no/wp-content/uploads/2020/07/envelope-solid-3.png'
          linkIcon = 'https://skygruppen.no/wp-content/uploads/2020/07/link-solid-2.png'
          mapsIcon = 'https://skygruppen.no/wp-content/uploads/2020/07/envelope-solid-3.png'
          link = {
            value: 'https://skygruppen.no',
            label: 'skygruppen.no',
          }
          break
      }
      console.log(emailIcon, linkIcon, mapsIcon)
      this.signature = `<p style='margin:0in;font-size:15px;font-family:"Calibri",sans-serif;color:#000000'>Med vennlig hilsen | Kind Regards</p>
<p style='margin:0in;font-size:15px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
<table style="border: none;">
    <tbody>
        <tr>
            <td style="padding:0in 0in 0in 0in;">
                <table style="border: none;">
                    <tbody>
                        <tr>
                            <td style="padding:0in 0in 0in 0in;">
                                <p style='margin:0in;font-size:15px;font-family:"Calibri",sans-serif;'><strong><span style='font-size:18px;font-family:    "Arial",sans-serif;color:black;'>${this.data.fullName}</span></strong></p>
                                <p style='margin:0in;font-size:15px;font-family:"Calibri",sans-serif;line-height:16.5pt;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>${this.data.title}</span></p>
                                <p style='margin:0in;font-size:15px;font-family:"Calibri",sans-serif;line-height:16.5pt;'><span style='font-size:14px;font-family:"Arial",sans-serif;color:black;'>${company}</span></p>
                            </td>
                            <td style="width:22.5pt;padding:0in 0in 0in 0in;"><br></td>
                            <td style="width:.75pt;border:none;border-left:solid ${color} 1.0pt;padding:0in 0in 0in 0in;"><br></td>
                            <td style="width:22.5pt;padding:0in 0in 0in 0in;"><br></td>
                            <td style="padding:0in 0in 0in 0in;">
                                <table style="border: none;">
                                    <tbody>
                                        <tr>
                                            <td style="width:22.5pt;padding:0in 0in 0in 0in;height:18.75pt;">
                                                <table style="border: none;">
                                                    <tbody>
                                                        <tr>
                                                            <td style="padding: 0in;vertical-align: bottom;">
                                                                <p style='margin:0in;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:18px;font-family:"Arial",sans-serif;'><img src="${phoneIcon}" alt="phone"></span></p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td style="padding:0in 0in 0in 0in;height:18.75pt;">
                                                <p style='margin:0in;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:18px;font-family:"Arial",sans-serif;color:black;'><a href="${this.data.phone}"><span style="font-size:12px;color:black;">${this.data.phone}</span></a></span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width:22.5pt;padding:0in 0in 0in 0in;height:18.75pt;">
                                                <table style="border: none;">
                                                    <tbody>
                                                        <tr>
                                                            <td style="padding: 0in;vertical-align: bottom;">
                                                                <p style='margin:0in;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:18px;font-family:"Arial",sans-serif;'><img src="${emailIcon}" alt="email"></span></p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td style="padding:0in 0in 0in 0in;height:18.75pt;">
                                                <p style='margin:0in;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:18px;font-family:"Arial",sans-serif;'><a href="mailto:${this.data.companyEmail}"><span style="font-size:12px;color:black;">${this.data.companyEmail}</span></a></span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width:22.5pt;padding:0in 0in 0in 0in;height:18.75pt;">
                                                <table style="border: none;">
                                                    <tbody>
                                                        <tr>
                                                            <td style="padding: 0in;vertical-align: bottom;">
                                                                <p style='margin:0in;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:18px;font-family:"Arial",sans-serif;'><img src="${linkIcon}" alt="website"></span></p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td style="padding:0in 0in 0in 0in;height:18.75pt;">
                                                <p style='margin:0in;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:18px;font-family:"Arial",sans-serif;'><a href="${link.value}"><span style="font-size:12px;color:black;">${link.label}</span></a></span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width:22.5pt;padding:0in 0in 0in 0in;height:18.75pt;">
                                                <table style="border: none;">
                                                    <tbody>
                                                        <tr>
                                                            <td style="padding: 0in;vertical-align: bottom;">
                                                                <p style='margin:0in;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:18px;font-family:"Arial",sans-serif;'><img src="${mapsIcon}" alt="adress"></span></p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td style="padding:0in 0in 0in 0in;height:18.75pt;">
                                                <p style='margin:0in;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:12px;font-family:"Arial",sans-serif;color:black;'></span><span style='font-size:12px;font-family:"Arial",sans-serif;color:black;'>${adress}</span></p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td style="padding:0in 0in 0in 0in;">
                <table style="border: none;width:315.0pt;">
                    <tbody>
                        <tr>
                            <td style="padding:0in 0in 0in 0in;height:7.5pt;"><br></td>
                        </tr>
                        <tr>
                            <td style="width:315.0pt;border:none;border-bottom:solid ${color} 0.75pt;padding:0in 0in 0in 0in;height:.75pt;"><br></td>
                        </tr>
                        <tr>
                            <td style="padding:0in 0in 0in 0in;height:7.5pt;"><br></td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td style="padding:0in 0in 0in 0in;">
                <table style="border: none;width:315.0pt;">
                    <tbody>
                        <tr>
                            <td style="padding: 0in;vertical-align: top;">
                                <p style='margin:0in;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:18px;font-family:"Arial",sans-serif;'><img width="190" src="${companyLogo}"></span></p>
                            </td>
                            <td style="padding: 0in;vertical-align: top;">
                                <div align="right" style='margin:0in;font-size:15px;font-family:"Calibri",sans-serif;'>
                                    <table style="border: none;">
                                        <tbody>
                                            <tr>
                                                <td style="padding:11.25pt 0in 0in 0in;">
                                                    <p style='margin:0in;font-size:15px;font-family:"Calibri",sans-serif;text-align:right;'><a href="${this.data.linkedin}"><img width="24" src="${linkedinLogo}" style="height: 0.25in; width: 0.25in;" alt="linkedin"></a></p>
                                                </td>
                                                <td style="width:3.75pt;padding:0in 0in 0in 0in;"><br></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
    </tbody>
</table>
`
    },
  },
}
</script>
